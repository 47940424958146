<template>
  <center>
    <div class="wrapper">
    <div class="table">
      
      <div class="tablerow header blue">
        <div class="cell">
          Nazwa
        </div>
        <div class="cell">
          Symbol
        </div>
        <div class="cell">
          Ilość
        </div>
        <div class="cell">
          Cena zakupu
        </div>
        <div class="cell">
          Cena
        </div>
        <div class="cell">
          Zysk
        </div>
        <div class="cell">
          Wartość
        </div>
        <div class="cell"><button class='button' @click="toggleEditAssets(tck)">Edytuj</button></div>
      </div>
      
      <div class="tablerow"  v-for="(td, idx) in sumAssetData()" :key="td.name">
        <div class="cell" data-title="Username">
          {{ td.name }}
        </div>
        <div class="cell" data-title="Email">
          {{ td.ticker }}
        </div>
        <div class="cell" data-title="Password">
          {{ td.amount }}
        </div>
        <div class="cell" data-title="Password">
          {{ formatMoney(td.buy_price) }}
        </div>
        <div class="cell" data-title="Password">
          {{ formatMoney(td.current_price) }}
        </div>
        <div class="cell" data-title="Active">
          {{ formatMoney(td.profit) }}
        </div>        
        <div class="cell" data-title="Password">
          <b>{{ formatMoney(td.value) }}</b>
        </div>
        <div  class="cell">
          <button v-if="editTickers == tck" class='button' @click="editAsset(idx, td)">Zmień</button>
          <button v-if="editTickers == tck" class='button' @click="removeAsset(idx, td)">Usuń</button>
        </div>
      </div>
      
    </div>
    <div class="row">
      <div>Wartość razem <b>{{ formatMoney(totalValue) }}</b> PLN</div>
    </div>
  
</div>

    <div  v-if="editTickers != ''">
      <div class="row">
        <label for="assetName">Nazwa</label>
        <input id="assetName" v-model="assetName" type="text" />
      </div>
      <div class="row">
        <label for="asset_buy_date">Data</label>
        <Datepicker v-model="asset_buy_date"></Datepicker>
      </div>
      <div class="row">
        <label for="ticker">Symbol</label>
        <input id="ticker" v-model="ticker" type="text" name="ticker" />
      </div>
      <div class="row">
        <label for="assetType">Rodzaj</label>
        <select id="assetType" v-model="assetType">
          <option v-for="option in assetTypes" v-bind:key="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="row">
        <label for="assetAmount">Ilość</label>
        <input
          id="assetAmount"
          v-model="assetAmount"
          type="number"
          name="assetAmount"
        />        
      </div>
      <div class="row">
        <label for="asset_buy_price">Cena zakupu</label>
        <input
          id="asset_buy_price"
          v-model="asset_buy_price"
          type="number"
          name="asset_buy_price"
        />
      </div>
      <div class="row">
        <label for="asset_memo1">Opis</label>
        <input id="asset_memo1" v-model="asset_memo1" type="text" />
      </div>
      <div class="row">
        <label for="asset_memo2">Uwagi</label>
        <input id="asset_memo2" v-model="asset_memo2" type="text" />
      </div>
      <input type="button" class='button'  value="Zapisz" @click="saveAsset" />
      <input type="button" class='button'  value="Wyczyść" @click="clearFields" />
    </div>
  </center>
</template>

<script>
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { Datepicker },
  data() {
    return {
      assetName: '',
      ticker: '',
      asset_type: 'crypto',
      assetAmount: 0,
      asset_buy_price: 0,
      asset_buy_date: new Date(),
      asset_memo1: '',
      asset_memo2: '',
      assetData: [],
      assetId: 0,
      assetValue: 0,
      assetIDLocal: 0,
      editTickers: '',
      sortedData: [],
      assetArr: [],
      totalValue: 0,
      assetTypes: ['crypto', 'bank', 'shares'],
      timer: '',
    }
  },
  mounted() {
    this.loadData()
    this.timer = setInterval(this.loadData, 100000)
  },
  computed: {
  },
  methods: {
    async loadData(){
      const res = await axios.get('/api/get_assets.php')
      console.log('loaded ', res)
      this.assetData = res.data
      this.sortData()
      console.log('mounted', this.assetData)
      console.log(this.sortedData)
    },

    sortData(){
      this.assetArr = []
      this.sortedData = {}
      this.totalValue = 0
      this.assetData.forEach(row => {
        this.sortedData[row.ticker] = row
        if (this.assetArr.indexOf(row.ticker) === -1)
          this.assetArr.push(row.ticker)
        this.totalValue += row.value
      })
    },
    sumAssetData(){
      const res = []
      this.assetData.forEach(row => {
        res.push(row)
      })
      return res
    },
    toggleEditAssets(tck){
      if (this.editTickers == tck)
        this.editTickers = ''
      else this.editTickers = tck
    },

    async saveAsset() {
      var result = ''
      var paramsHTML = new URLSearchParams();
      paramsHTML.append('name', this.assetName);
      paramsHTML.append('asset_type', this.asset_type);
      paramsHTML.append('ticker', this.ticker);
      paramsHTML.append('amount', this.assetAmount);
      paramsHTML.append('buy_price', this.asset_buy_price);
      paramsHTML.append('current_price', this.current_price);
      paramsHTML.append('date', this.asset_buy_date);
      paramsHTML.append('memo1', this.asset_memo1);
      paramsHTML.append('memo2', this.asset_memo2);
      paramsHTML.append('submit', 'true');
      if (this.assetId == 0) {
        result = await axios.post('/api/insert_asset.php', paramsHTML)
        this.assetData.push(result.data)
        console.log('insert result', result)
        this.clearFields()
      }
      else {
        console.log('_id: ', this.assetId)
        paramsHTML.append('id', this.assetId);
        result = await axios.post('/api/update_asset.php', paramsHTML)
        console.log('update result', result)
        this.assetData[this.assetIDLocal] = result.data
        this.clearFields()
      }
      console.log('result', this.assetData)
      this.assetId = 0
    },

    async editAsset(idx, td){
      console.log(td)
      this.assetId = td.id
      this.asset_type = td.asset_type
      this.assetName = td.name
      this.ticker = td.ticker
      this.assetAmount = td.amount
      this.assetValue = td.value
      this.asset_buy_price = td.buy_price
      this.current_price = td.current_price
      this.assetIDLocal = idx
      this.asset_memo1 = td.asset_memo1
      this.asset_memo2 = td.asset_memo2
      this.asset_buy_date = td.date
      // this.tickerData = await axios.put('http://localhost:9000/api/vault:'+td._id, params)
    },

    async removeAsset(idx, td){
      var result = idx
      var paramsHTML = new URLSearchParams()
      console.log('_id: ', td.id)
      paramsHTML.append('id', td.id)
      paramsHTML.append('submit', 'true')
      result = await axios.post('/api/delete_asset.php', paramsHTML)
      console.log('del result', result)
      this.assetData.splice(idx, 1)
    },

    getTickerData(tck){
      let res = []
      this.assetData.forEach(td => {
        if (td.ticker == tck)
          res.push(td)
      })
      return res
    },

    formatMoney(value) {
      let val = (value/1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    clearFields(){
      this.assetId = 0
      this.asset_type = ''
      this.assetName = ''
      this.ticker = ''
      this.assetAmount = 0
      this.assetValue = 0
      this.asset_buy_price = 0
      this.current_price = 0
      this.assetIDLocal = 0
      this.asset_memo1 = ''
      this.asset_memo2 = ''
      this.asset_buy_date = new Date()
    }
  },
}
</script>

<style scoped>
.row{
  width: 100%;
  display: flex;
  background: #5b507a;
  color: #fafafa;
  padding: 1px;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #3b3b3b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  background: #2b2b2b;
}
@media screen and (max-width: 580px) {
  body {
    font-size: 16px;
    line-height: 22px;
  }
}

.wrapper {
  margin: 0 auto;
  padding: 40px;
  max-width: 800px;
}

.table {
  margin: 0 0 40px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
  
}
@media screen and (max-width: 580px) {
  .table {
    display: block;
  }
}

.tablerow {
  display: table-row;
  background: rgb(50, 45, 121);
  color: #c9c9c9;
}
.tablerow:nth-of-type(odd) {
  background: #48479b;
  color: #bdbdbd;
}
.tablerow.header {
  font-weight: 900;
  color: #ffffff;
  background: #ea6153;
}
.tablerow.green {
  background: #27ae60;
}
.tablerow.blue {
  background: #2980b9;
}
@media screen and (max-width: 580px) {
  .tablerow {
    padding: 14px 0 7px;
    display: block;
  }
  .tablerow.header {
    padding: 0;
    height: 6px;
  }
  .tablerow.header .cell {
    display: none;
  }
  .tablerow .cell {
    margin-bottom: 10px;
  }
  .tablerow .cell:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }
}

.cell {
  padding: 6px 12px;
  display: table-cell;
}
@media screen and (max-width: 580px) {
  .cell {
    padding: 2px 16px;
    display: block;
  }
}
.button {
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  color: rgb(189, 189, 189);
  padding: 4px;  
  background-color: #2d2752;
}

div{
  font-size: 16px;
}

input{
  font-size: 16px;
  background-color: rgb(50, 45, 121);
  color: #969696;
  width: 85%;
}

label {
  text-align: left;
  width: 15%;
  padding-left: 5px;
}
</style>
