<template>
  <div class="container">
    <div class="navbarBox">
    </div>
    <div class="box1">
      <EventsCalendar/>
    </div>
    <div class="box2">
      <AssetsInventory/>
    </div>
  </div>
</template>

<script>
import EventsCalendar from './components/EventsCalendar.vue'
import AssetsInventory from './components/AssetsInventory.vue'
export default {
  name: "App",
  components: { EventsCalendar, AssetsInventory },}
</script>

<style>
.container {
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-template-rows: 10% 1fr;
  grid-template-areas: 
  "navbarBbox navbarBbox"
  "box1 box2";
}

.title {
  font-family: 'Quicksand', 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.navbarBbox{
  grid-area: navbarBbox;
}
.box1{
  grid-area: box1;
}

.box2{
  grid-area: box2;
  line-height: 1;
}

</style>
