import { createApp } from 'vue'
import App from './App.vue'

// eslint-disable-next-line
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)
// eslint-disable-next-line
app.component('Datepicker', Datepicker)

app.mount('#app')