<template>
<div>
  <b-field grouped>
    <b-datetimepicker v-model="itemDate" icon="calendar-today"></b-datetimepicker>
    <div class="control is-expanded">
      <input class="input" v-model="itemName"  placeholder="Nazwa"  type="text"/>
    </div>
    <div class="control is-expanded">
      <input class="input" v-model="itemDescr" placeholder="Opis" type="text"/>
    </div>
    <div class="control">
      <a class="button is-info" @click="addItem" :disabled="!itemName">+</a>
    </div>

  </b-field>

    <nav class="panel is-info">
      <p class="panel-heading ">
        Do zrobienia
      </p>
    
        <a class="panel-block" v-for="(item, i) in todoItems" :key="item._id">
          <div class="columns">
            <b-datetimepicker v-if="isSelected(item)" v-model="editedDate" icon="calendar-today"></b-datetimepicker>
            <input type="text" class="column input" v-if="isSelected(item)" v-model="editedName"/>
            <input type="text" class="column input" v-if="isSelected(item)" v-model="editedDescr"/>
            <div v-else class="column">
            <p class="tags has-addons">
                <span :class="setColorTagByDate(item)">{{ $moment(item.date).format('hh:mm DD-MM-YYYY') }}</span>
                <span class="tag is-info is-small">{{ item.name }}</span>
                <span v-if="item.description" class="tag is-light is-small">{{ item.description }}</span>
            </p>
            </div>
            <div class="column is-narrow">
              <span class="icon has-text-info" @click="isSelected(item) ? unselect() : copy(item)">
                <b-icon v-if="!isSelected(item)"
                  icon="content-copy"
                  size="is-small">
                </b-icon>
              </span>
            </div>
            <div class="column is-narrow">
              <span class="icon has-text-info" @click="isSelected(item) ? unselect() : check(item, i, true)">
                <b-icon v-if="!isSelected(item)"
                  icon="check"
                  size="is-small">
                </b-icon>
              </span>
            </div>
            <div class="column is-narrow">
              <span class="icon has-text-info" @click="isSelected(item) ? unselect() : select(item)">
                <b-icon v-if="isSelected(item)"
                  icon="close"
                  size="is-small">
                </b-icon>
                <b-icon v-if="!isSelected(item)"
                  icon="pencil"
                  size="is-small">
                </b-icon>
              </span>
            </div>
            <div class="column is-narrow">
              <span class="icon has-text-info" @click="isSelected(item) ? updateItem(item, i) : removeItem(item, i)">
                <b-icon v-if="isSelected(item)"
                  icon="content-save"
                  size="is-small">
                </b-icon>
                <b-icon v-if="!isSelected(item)"
                  icon="trash-can"
                  size="is-small">
                </b-icon>
              </span>
            </div>
          </div>
        </a>

    </nav>


    <nav class="panel is-dark">
      <p class="panel-heading">
        Zrobione
      </p>

        <a class="panel-block" v-for="(item, i) in completedItems" :key="item._id">
          <div v-if="item.completed" class="columns">
            <input type="text" class="column input" v-if="isSelected(item)" v-model="editedName"/>
            <div v-else class="column">
            <p class="tags has-addons">
                <span :class="setColorTagByDate(item)">{{ $moment(item.date).format('hh:mm DD-MM-YYYY') }}</span>
                <span class="tag is-info is-small">{{ item.name }}</span>
                <span v-if="item.description" class="tag is-light is-small">{{ item.description }}</span>
            </p>
            </div>
            <div class="column is-narrow">
              <span class="icon has-text-info" @click="isSelected(item) ? unselect() : copy(item)">
                <b-icon v-if="!isSelected(item)"
                  icon="content-copy"
                  size="is-small">
                </b-icon>
              </span>
            </div>            
            <div class="column is-narrow">
              <span class="icon has-text-info" @click="isSelected(item) ? unselect() : check(item, i, false)">
                <b-icon v-if="!isSelected(item)"
                  icon="check-underline"
                  size="is-small">
                </b-icon>
              </span>
            </div>
            <div class="column is-narrow">
              <span class="icon has-text-info" @click="isSelected(item) ? updateItem(item, i) : removeItem(item, i)">
                <b-icon v-if="isSelected(item)"
                  icon="content-save"
                  size="is-small">
                </b-icon>
                <b-icon v-if="!isSelected(item)"
                  icon="trash-can"
                  size="is-small">
                </b-icon>
              </span>
            </div>
          </div>
        </a>

    </nav>

    <div class="container">
      
    </div>
  </div>
</template>

<script>
//class="material-icons"
import axios from 'axios'
export default {
  data() {
    return {
      items: [],
      completedItems: [],
      todoItems: [],
      itemName: '',
      itemDescr: '',
      itemDate: new Date(),
      editedDate: new Date(),
      editedName: '',
      editedDescr: '',
      selected: {_id: 0},
    }
  },

  async mounted(){
    const response = await axios.get('http://localhost:9000/api/calendar/')
    this.items = response.data
    this.updateItems()
  },
  methods: {
    updateItems(){
      this.completedItems = []
      this.todoItems = []
      this.items.forEach((item, idx) => {
        item.idx = idx
        if (item.completed) 
          this.completedItems.push(item)
        else 
          this.todoItems.push(item)
      })
    },
    async addItem() {
      const response = await axios.post('http://localhost:9000/api/calendar/', {
        name: this.itemName, description: this.itemDescr, date: this.itemDate
      })
      this.items.push(response.data)
      this.updateItems()
      this.itemDescr = ''
      this.itemName = ''
    },
    async updateItem(item) {
      const response = await axios.put('http://localhost:9000/api/calendar/'+item._id, {
        name: this.editedName, description: this.editedDescr, date: this.editedDate
      })
      this.items[item.idx] = response.data._doc
      this.updateItems()
      this.unselect()
      console.log(this.items)
      console.log()
    },
    async removeItem(item) {
      await axios.delete('http://localhost:9000/api/calendar/' + item._id)
      this.items.splice(item.idx, 1)
      this.updateItems()
    },
    select(item){
      this.selected = item
      this.editedDate = new Date(item.date)
      this.editedName = item.name
      this.editedDescr = item.description
    },
    copy(item){
      this.itemName = item.name
      this.itemDescr = item.description
      this.itemDate = new Date(item.date)
    },
    unselect(){
      this.selected = {_id: 0}
      this.editedName = ''
    },
    async check(item, i, completed){
      const response = await axios.put('http://localhost:9000/api/calendar/'+item._id, {
        completed: completed
      })
      this.items[item.idx] = response.data._doc
      this.updateItems()
      this.unselect()
    },
    isSelected(item){
      return this.selected._id === item._id
    },
    setColorTagByDate(item){
      if (item.completed)
        return 'tag is-dark is-small'
      var now = new Date()
      var itemDate = new Date(item.date)
      const elapsed = (itemDate.getTime() - now.getTime()) / 1000
      console.log(item, elapsed, itemDate)
      if (elapsed < 0)
        return 'tag is-danger is-small'
      if (itemDate.getDate() === now.getDate())
        return 'tag is-warning is-small'
      if (itemDate.getDate() === now.getDate()+1)
        return 'tag is-success is-small'
      return 'tag is-primary is-small'
    }
  }
}
</script>

<style scoped>
.calendar {
  margin: auto;
  margin-top: 1rem;
  max-width: 700px;
  background-color: darkslateblue;
}
.calendar-row{
  background-color: #2c2b30;
}
.columns{
  width: 100%;
}
.column{
  padding: 0;
}
.panel-block{
  padding: 1rem;
  border-bottom: 1px solid #000000;
  width: 100%;
}
</style>
